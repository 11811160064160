import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useInterval, useLocalStorage } from "react-use";
import { AsyncStatus, isLoadingStatus } from "../../models/async-status";
import { fetchFeatureFlags } from "../../reducers/feature-flags-reducer";
import { fetchSession } from "../../reducers/session";
import { NoContextRoutes } from "../../routes/routes";
import { getApiToken } from "../../services/security";
import { useAppDispatch, useSelector } from "../../store/store";
import AuthRenderer from "./auth-renderer";
import { loginPath } from "../../util/constants";
import { AuthContextProps, hasAuthParams, useAuth } from "react-oidc-context";
import { logToApi } from "../../services/logging";
import { LogLevel, UiLogBody } from "../../models/logging/UiLogBody";

export interface Props {
  children: React.ReactNode;
}

export default function AuthWrapper(props: Props) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const session = useSelector((state) => state.session);
  const featureFlagStatus = useSelector((state) => state.featureFlags.status);
  const [isLoadingFeatureFlags, setIsLoadingFeatureFlags] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [, setImpersonated] = useLocalStorage("impersonated", false);
  const [, setAccessToken] = useLocalStorage("accessToken", "", { raw: true });
  const [, setStartingUrl] = useLocalStorage("startingUrl", "", { raw: true });
  const [, setOktaToken] = useLocalStorage("oktaToken", "", { raw: true });
  const [, setEnterpriseId] = useLocalStorage("enterpriseId", "", {
    raw: true,
  });
  const loginUrl = `${loginPath}?exitUrl=${encodeURI(window.location.href)}`;
  const allowedHosts = [
    "nq.individuals.pilot.principal.com",
    "nq.individuals.principal.com",
    "secure05.principal.com",
    "secure05.pilot.principal.com",
    "localhost.staging.principal.com",
  ];
  const retirementHost = ["secure05.principal.com/retirement/"];

  function redirect(): boolean {
    const documentReferrer = document.referrer.toString();
    console.log("Document Referrer: %s", documentReferrer);
    try {
      if (documentReferrer === "") {
        return false;
      } else {
        const referrerUrl: string = new URL(documentReferrer).toString();
        console.log("Referrer URL: %s", referrerUrl);
        if (retirementHost.includes(referrerUrl)) {
          console.log(
            `retirementHost includes: ${retirementHost.includes(referrerUrl)}`
          );
        }
        const originURL = new URL(documentReferrer).host;
        console.log("Origin URL: %s", originURL);
        console.log(
          `Allowed hosts includes:  ${!allowedHosts.includes(originURL)}`
        );
        return !allowedHosts.includes(originURL);
      }
    } catch (e) {
      console.log(
        "Failed to parse origin URL: %s :: error: %s",
        documentReferrer,
        e.message
      );
      return true;
    }
  }

  useEffect(() => {
    if (redirect()) {
      console.log("Redirect needed!");
      if (window.location.pathname + window.location.search !== "") {
        setStartingUrl(window.location.pathname + window.location.search);
      }
      auth.signinRedirect();
    }
  }, []);

  function getLoggableAuth(auth: AuthContextProps, errorMessage: string) {
    return {
      hasAuthParams: hasAuthParams(),
      hasTriedSignin: hasTriedSignin,
      isAuthenticated: auth.isAuthenticated,
      activeNavigator: auth.activeNavigator,
      isLoading: auth.isLoading,
      error: errorMessage,
      user: auth.user
        ? {
            access_token: auth.user.access_token,
            scope: auth.user.scope,
            profile: auth.user.profile,
            expires_at: auth.user.expires_at,
            token_type: auth.user.token_type,
            session_state: auth.user.session_state,
          }
        : null,
    };
  }

  const fetchApiToken = useCallback(
    async (oktaToken: AuthContextProps) => {
      try {
        const apiToken = await getApiToken(oktaToken?.user);
        console.log("apiToken", apiToken);
        setAccessToken(apiToken.access_token);
        setEnterpriseId(apiToken.businesscontext?.affiliateId);
        setImpersonated(apiToken.businesscontext.impersonated);
        setOktaToken(oktaToken?.user?.access_token || "");

        setIsValidToken(true);
      } catch (e) {
        if (oktaToken) {
          console.log(`new Token time...${JSON.stringify(oktaToken)} `);
          auth.signinRedirect(); // Redirect to Okta to activate a usable token.
        }
        setIsValidToken(false);
        setIsLoadingFeatureFlags(false);
        console.log(`We are sending you to log in at ${loginUrl}.`);
        window.location.replace(loginUrl);
      }
      setIsLoadingToken(false);
    },
    [setAccessToken, setEnterpriseId, setStartingUrl]
  );

  // sessionStorage.clear() should be called at some point in the okta login
  // automatically sign-in
  useEffect(() => {
    //Sometimes auth.error is an empty object "{}" which is annoyingly truthy
    //so we instead check that it has an error with a message
    if (auth.error && "message" in auth.error) {
      if (auth.error.message.includes("No matching state found in storage")) {
        console.log("recover from bad oidc state");
        localStorage.clear();
        const redirectUrl = new URL(window.location.href);
        redirectUrl.searchParams.delete("code");
        redirectUrl.searchParams.delete("state");
        window.location.href =
          redirectUrl.pathname + "?" + redirectUrl.searchParams;
      }
      const authMessage = getLoggableAuth(auth, auth.error.toString());
      const message =
        "Automatic Okta sign-in errored for some reason, auth: {" +
        JSON.stringify(authMessage) +
        "}";
      const logBody = {
        message: message,
        url: window.location.href,
        level: LogLevel.ERROR,
      } as UiLogBody;
      logToApi(logBody);
      console.log("Automatic Okta sign-in errored. Cry", auth.error);
    }
    if (auth.isAuthenticated) {
      console.log("auth.isAuthenticated");
      if (auth.user?.access_token !== null && auth.user?.access_token !== "") {
        fetchApiToken(auth);
      } else {
        const loggableAuth = getLoggableAuth(
          auth,
          auth.error ? auth.error.toString() : "unknown oidc error"
        );
        console.log(`loggableAuth: ${loggableAuth}`);
        const logBody = {
          message:
            "Okta didn't receive an access token, auth: {" +
            JSON.stringify(loggableAuth) +
            "}",
          url: window.location.href,
          level: LogLevel.ERROR,
        } as UiLogBody;
        console.log(`logBody: ${logBody}`);
        logToApi(logBody);
      }
    } else if (
      !hasAuthParams() &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      console.log(`SetHasTriedSigning to true`);
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  useEffect(() => {
    if (isValidToken && session.status !== AsyncStatus.done) {
      dispatch(fetchSession());
    }
  }, [dispatch, isValidToken, session.status]);

  useEffect(() => {
    if (featureFlagStatus === AsyncStatus.not_fetched && isValidToken) {
      dispatch(fetchFeatureFlags());
    }
    setIsLoadingFeatureFlags(
      featureFlagStatus === AsyncStatus.loading ||
        featureFlagStatus === AsyncStatus.not_fetched
    );
  }, [dispatch, featureFlagStatus, isValidToken]);

  useInterval(fetchApiToken, 15 * 60 * 1000);

  const isLoadingSession = isLoadingStatus(session.status);
  const isValidSession = session.status !== AsyncStatus.error;

  if (!isLoadingToken && !isValidSession) {
    history.push(NoContextRoutes.AccountsLanding);
  }

  return (
    <AuthRenderer
      isLoadingToken={isLoadingToken}
      isLoadingSession={isLoadingSession}
      isValidToken={isValidToken}
      isLoadingFeatureFlags={isLoadingFeatureFlags}
    >
      {props.children}
    </AuthRenderer>
  );
}
